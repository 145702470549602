import getByPath from 'lodash.get'
import { requiredArray } from './validations'
import {
  getEnabledQuestionTypes,
  getFirstEmailQuestion,
  getLabelByKind,
  getSubListEmailQuestion,
  hasAutoResponse,
  hasPaymentConfirm
} from '../configs/questionTypesAdminConfig'
import { addYAtTheEnd } from './helpers'

const subQuestions = name => (value, values) => {
  const childrenAttributes = getByPath(values, `${name}.children_attributes`)
  let hasChildren = false
  childrenAttributes &&
    Object.keys(childrenAttributes).forEach(key => {
      if (!hasChildren) hasChildren = childrenAttributes[key].filter(v => !v?._destroy).length > 0
    })
  if (childrenAttributes === undefined || !hasChildren) {
    return requiredArray('Configura al menos una pregunta', 1)(value)
  }
}

const validateFirstEmail = (_value, values) => {
  const trackableQuestion = getFirstEmailQuestion(values) || getSubListEmailQuestion(values)
  const reason = getFirstEmailRequiredReason(values)
  const requireQuestion = Array.isArray(trackableQuestion) ? trackableQuestion.length === 0 : !trackableQuestion

  if (Boolean(reason) && requireQuestion) {
    return `Para poder enviar la ${reason} al ciudadano, debes agregar una pregunta de Correo o teléfono de contacto.`
  }
}

const validateFirstEmailRequired = kind => (value, values) => {
  const reason = getFirstEmailRequiredReason(values)
  const message = `Para poder enviar una ${reason} al ciudadano, debes colocar como obligatoria la pregunta de ${
    kind === 'email' ? 'Correo electrónico' : 'Correo o teléfono de contacto'
  }.`
  return !value ? message : undefined
}

const getFirstEmailRequiredReason = values => {
  const autoResponse = hasAutoResponse(values)
  const paymentConfirm = hasPaymentConfirm(values)

  if (autoResponse && paymentConfirm) return 'respuesta automática y confirmación de pago'
  if (autoResponse) return 'respuesta automática'
  if (paymentConfirm) return 'confirmación de pago'
}

const validateAutoResponse = firstEmailQuestion => value => {
  if (!firstEmailQuestion && value !== 'no')
    return 'Esta opción requiere que tu formulario tenga al menos una pregunta de tipo correo electrónico'
}

const validateAutoResponseDefaultQuestion =
  (defaultQuestions = []) =>
  () => {
    return defaultQuestions.includes('email_applicant')
      ? undefined
      : 'Esta opción requiere que tu formulario tenga al menos una pregunta de tipo correo electrónico'
  }

const validateApplicantQuestionsInProcedureManagement = formType => stepsValue => {
  if (formType === 'procedure_management' || formType === 'enabled_procedure_management') {
    const enabledApplicantQuestionTypes = getEnabledQuestionTypes(
      stepsValue,
      null,
      'steps_attributes[0].questions_attributes',
      false,
      formType,
      'applicant'
    )
    if (enabledApplicantQuestionTypes.length) {
      let message = 'Debes agregar'
      if (enabledApplicantQuestionTypes.length === 1) {
        if (enabledApplicantQuestionTypes[0] === 'email_phone_applicant') {
          message = 'Para el envío de la constancia, debes agregar la pregunta'
        } else {
          message += ' la pregunta'
        }
      } else {
        message += ' las preguntas'
      }
      const questionTypeLabels = enabledApplicantQuestionTypes.map(questionType => `"${getLabelByKind(questionType)}"`)
      message += ' de Información del solicitante ' + addYAtTheEnd(questionTypeLabels)
      return message
    }
  }
}

const isEmailPhoneApplicantWithAutomaticAnswer = () => (value, values) => {
  const reason = getFirstEmailRequiredReason(values)
  const message = `Para que el ciudadano reciba la ${reason}, debes marcar la opción "Correo electrónico"`
  return Boolean(reason) && value !== 'email' ? message : undefined
}

const minUniqueScoreSelected =
  (message = 'Esta pregunta tiene puntaje, por lo que debes establecer al menos una respuesta correcta.') =>
  values => {
    const hasCorrect = values?.some(el => el?.correct_answer?.toString() === 'true')
    return hasCorrect ? undefined : message
  }

export default {
  subQuestions,
  validateFirstEmail,
  validateFirstEmailRequired,
  validateAutoResponse,
  validateApplicantQuestionsInProcedureManagement,
  isEmailPhoneApplicantWithAutomaticAnswer,
  getFirstEmailRequiredReason,
  validateAutoResponseDefaultQuestion,
  minUniqueScoreSelected
}
